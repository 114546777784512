import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eating',
  templateUrl: './eating.component.html',
  styleUrls: ['./eating.component.css']
})
export class EatingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
