import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UserService } from './services/user.service';
import { JumbotronService } from './services/jumbotron.service';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { PhilosophycardsComponent } from './components/philosophycards/philosophycards.component';
import { AppRoutingModule } from './/app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MovingComponent } from './components/moving/moving.component';
import { NewHomeComponent } from './components/new-home/new-home.component';
import { EatingComponent } from './components/eating/eating.component';
import { ThinkingComponent } from './components/thinking/thinking.component';
import { TaoComponent } from './components/tao/tao.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    JumbotronComponent,
    PhilosophycardsComponent,
    HomeComponent,
    AboutUsComponent,
    TestimonialsComponent,
    ContactUsComponent,
    MovingComponent,
    NewHomeComponent,
    EatingComponent,
    ThinkingComponent,
    TaoComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [UserService, JumbotronService],
  bootstrap: [AppComponent]
})
export class AppModule { }
