import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

//import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { ContactUsComponent} from './components/contact-us/contact-us.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { MovingComponent } from './components/moving/moving.component';
import { NewHomeComponent } from './components/new-home/new-home.component';
import { EatingComponent } from './components/eating/eating.component';
import { ThinkingComponent } from './components/thinking/thinking.component';
import { TaoComponent } from './components/tao/tao.component';

const routes: Routes = [
{ path: '', component: HomeComponent },
{ path: 'contact-us', component: ContactUsComponent },
{ path: 'about-us', component: AboutUsComponent },
{ path: 'testimonials', component: TestimonialsComponent },
{ path: 'moving', component: MovingComponent },
{ path: 'new-home', component: NewHomeComponent },
{ path: 'eating', component: EatingComponent},
{path: 'thinking', component: ThinkingComponent},
{path: 'tao', component: TaoComponent}
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ],
  //declarations: []
})
export class AppRoutingModule { }
