import { Component, OnInit } from '@angular/core';
import { Jumbotron } from '../../models/Jumbotron'

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.css']
})
export class JumbotronComponent implements OnInit {
  jumbotron: Jumbotron = {
    display: '',
    lead: '',
    secondLead: ''

  }


  constructor() { }

  ngOnInit() {
    this.jumbotron = {
      display:'Applied Fitness Therapy™',
      lead: `Applying proven thinking, eating and moving strategies for better, holistic fitness, seamlessly into YOUR life, is the science and art of Applied Fitness Therapy™(AFT).`,
      secondLead: `In working with us you will learn how to find your way, on your terms, to your best health, fitness and wellness as you define it. No judgment, no diets, no crazy weight lifting or cardio routines, just strategies and perspectives which will work for you in your current situation.`
    }
  }

}
