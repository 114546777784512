import { Component, OnInit } from '@angular/core';
import { Moving } from '../../models/Moving';

@Component({
  selector: 'app-moving',
  templateUrl: './moving.component.html',
  styleUrls: ['./moving.component.css']
})
export class MovingComponent implements OnInit {
  moving: Moving[];

  constructor() { }

  ngOnInit() {
    this.moving = [
      {
        image: 'https://loremflickr.com/600/400',
        title:'Manual \& Movement Therapy',
        para1:'Massage Therapy is thought of as relaxation, oils, lotions and New Age music. We changed that. As Licensed Massage Therapists, we provide manual therapy with the goals of decreased pain, increased function, muscle balance and clear neural pathways so you CAN move.',
        para2: 'We combined existing soft-tissue techniques into a unique blend. The result is the potential ability to move the right way in 80% less time than traditional approaches. Drawing on these proven manual therapy techniques, we have defined an approach to quickly and safely get you and keep you moving. The way we apply these manual techniques gives us a unique \“body map\” of how you hold and manage tension. With that knowledge, we can take you from hands on, \“table time,\” into specifically designed holistic corrective exercise and functional fitness programs which address your own map, uniquely, quickly and effectively.',
        para3: ''
      },
      {
        image: 'https://loremflickr.com/600/401',
        title: 'Functional Fitness \& Sport Performance',
        para1:'We don’t think about our exercise and moving sessions as workouts. You can call them that, many times we pivot and shift during a “traditional” workout to address emerging muscle balance issues. Things your body map indicated could be an issue often pop up with specific movements. When they do, we adjust immediately.  We keep you moving, give you some corrective exercise homework, and keep focusing on your movement-based fitness goals in session. Applied Fitness Therapists™ slide seamlessly between soft-tissue manual and corrective techniques into sweat sessions if that’s part of your path. Eventually, you will be able to just move, stretch and improve—no hands needed.',
        para2:'',
        para3: ''
      }
    ];
  }

}
