import { Component, OnInit } from '@angular/core';
import { Tao } from '../../models/Tao';

@Component({
  selector: 'app-tao',
  templateUrl: './tao.component.html',
  styleUrls: ['./tao.component.css']
})
export class TaoComponent implements OnInit {
  taos: Tao [];

  constructor() { }

  ngOnInit() {

    this.taos = [
      {
        image: 'https://static.wixstatic.com/media/efe3c7d9a61f44b6a21efa9da29516a3.jpg/v1/fill/w_450,h_280,al_c,q_85,usm_0.66_1.00_0.01/efe3c7d9a61f44b6a21efa9da29516a3.webp',
        title: 'Eating Truism \#21',
        text: 'Everyone responds to changing their eating and moving habits. You got the body you got by doing what you did. At some point, we are responsible for what we eat and how much and when we move. The learned habits of yesteryear are in effect, unless we consciously, intentionally, consistently change them to the ones which will work for us today.'
      },
      {
        image: 'https://static.wixstatic.com/media/162c43aaa3bb40f39da6429626b0183e.jpg/v1/fill/w_450,h_280,al_c,q_85,usm_0.66_1.00_0.01/162c43aaa3bb40f39da6429626b0183e.webp',
        title: 'Eating Truism \#20',
        text: 'You must design and own your eating lifestyle in order to accommodate your ever changing lifestyle.The natural extension of Eating Truism \#18 is Truism \#19. So in \#18 we talked about planning for the inevitable change of life. We know life is change. That\'s what it does. Don\'t expect it to cooperate with your new fangled eating habits. Save yourself the frustration of expecting it to do so.'
      },
      {
        image: 'https://static.wixstatic.com/media/ad2051_0a208e4a64c94e7690e6cc69920d1318~mv2.jpg/v1/fill/w_450,h_280,al_c,q_85,usm_0.66_1.00_0.01/ad2051_0a208e4a64c94e7690e6cc69920d1318~mv2.webp',
        title: 'Eating Truism \#19',
        text: 'Your method, personal path, and daily practice of eating must honor your everyday madness. Plan for it.Set yourself up for success. The better your new eating habits fit into your existing lifestyle, the easier they will be sustain. It doesn\'t matter if it will take longer. Skip the rapid results. Find the things that you can do right now, everyday, that work. Make a little progress everyday. If it is easy enough to do, you will be able to make progress consistently.'
      },
      {
        image: 'https://static.wixstatic.com/media/1907b8f6ee2ab9086e40a464861c2d40.jpg/v1/fill/w_450,h_280,al_c,q_85,usm_0.66_1.00_0.01/1907b8f6ee2ab9086e40a464861c2d40.webp',
        title: 'Eating Truism \#18',
        text: 'Your method, personal path, and daily practice of eating must honor your everyday madness. Plan for it. Set yourself up for success. The better your new eating habits fit into your existing lifestyle, the easier they will be sustain. It doesn\'t matter if it will take longer. Skip the rapid results. Find the things that you can do right now, everyday, that work. Make a little progress everyday. If it is easy enough to do, you will be able to make progress consistently.'
      }
     

    ];
  }

}
